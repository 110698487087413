angular
  .module("app")
  .component("appStep2", {
    templateUrl: "app/templates/step2.html",
    controller: ["$scope", "formValueFactory", function ($scope, formValueFactory) {
      this.nextStep = nextStep;
      this.values = formValueFactory.step2;
      function nextStep() {
        this.form2.$setSubmitted();
        if (this.form2.$valid) {
          $scope.$emit("nextStep");
          formValueFactory.frelancerCost();
        }
      }
    }],
    controllerAs: "appStep2"
  });
