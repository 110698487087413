angular
  .module("app")
  .component("appMain", {
    templateUrl: "app/templates/main.html",
    controller: ["$scope", "$rootScope", "appConfig", "$timeout", function ($scope, $rootScope, appConfig, $timeout) {
      var appMain = this;

      this.languages = { "es": "Español", "pt": "Português", "en": "English" };
      this.languageSelectedName = this.languages[appConfig.lang];
      appMain.currentDate = new Date();
      appMain.activeTab = 0;
      var old_tab = appMain.activeTab;
      appMain.nextStep = nextStep;
      $scope.$on("nextStep", function () {
        appMain.nextStep();
      });

      $scope.$watch("appMain.activeTab", function () {

      });

      function nextStep() {

        switch (appMain.activeTab) {
          case 0:
            // validar el primer formulario
            $timeout(function () {
              jQuery('body').animate({ scrollTop: jQuery("#tab-2").offset().top - 61 }, '200');
              jQuery("#input_sickDays").focus();
            }, 300);
            break;
          case 1:
            // validar el segundo formulario
            $rootScope.$broadcast("selectStep3");
            break;
          case 2:

            // validar el segundo formulario
            break;
          default:
            return false;
        }
        if (appMain.activeTab < 2) {
          appMain.activeTab++;
        }
      }
    }],
    controllerAs: "appMain"
  });
