angular.module('app')
    .factory("formValueFactory", function () {
        return {
            step1: {
                currency: null,
                currentSalary: null,
                office: null,
                internet: null,
                software: null,
                phone: null,
                newLaptop: null,
                marketing: null,
                insurance: null,
                accountant: null,
                healthInsurance: null,
                taxes: null,
                others: null
            },
            step2: {
                yearlyBillableHours: 2080,
                sickDays: null,
                businessDaysOfVacation: null,
                nonbillableHoursPercentage: null,
                holidays: null
            },
            frelancerCost: function () {
                var vars = [
                    "office",
                    "internet",
                    "software",
                    "phone",
                    "newLaptop",
                    "insurance",
                    "accountant"
                ];
                var sumatory = 0;
                var is_all = true;
                for (var i in vars) {
                    var value = this.step1[vars[i]];
                    if (value != null && angular.isNumber(value) && value >= 0) {
                        sumatory += value;
                    } else {
                        is_all = false;
                    }
                }
                if (is_all) {
                    return sumatory;
                } else {
                    return null;
                }

            },
            getLivingCost: function () {
                var vars = [
                    "healthInsurance",
                    "taxes",
                    "others"
                ];
                var sumatory = 0;
                var is_all = true;
                for (var i in vars) {
                    var value = this.step1[vars[i]];
                    if (value != null && angular.isNumber(value) && value >= 0) {
                        sumatory += value;
                    } else {
                        is_all = false;
                    }
                }
                if (is_all) {
                    return sumatory;
                } else {
                    return null;
                }
            },
            expensesAsFreelancer: function () {
                var freelancerCost = this.frelancerCost();
                var livingCost = this.getLivingCost();
                if (null != freelancerCost && null != livingCost) {
                    return freelancerCost + livingCost;
                }
            },
            getBillableHours: function () {
                var vars = [
                    "sickDays",
                    "businessDaysOfVacation",
                    "holidays"
                ];
                var sumatory = 0;
                var is_all = true;
                for (var i in vars) {
                    var value = this.step2[vars[i]];
                    if (value != null && angular.isNumber(value) && value >= 0) {
                        sumatory += value * 8;
                    } else {
                        is_all = false;
                    }
                }
                if (is_all) {
                    return this.step2.yearlyBillableHours - sumatory;
                } else {
                    return null;
                }
            },
            getBillableYearHoursTotal: function () {
                var billable = this.getBillableHours();
                var nonBillablepercent = this.step2.nonbillableHoursPercentage;
                if (null != billable && angular.isNumber(nonBillablepercent) && nonBillablepercent > 0) {
                    var nonBillablepercentUnit = nonBillablepercent / 100;
                    var nombillabeHours = nonBillablepercentUnit * billable;
                    return billable - Math.ceil(nombillabeHours);
                } else {

                    return null;
                }
            },
            getAdjustedMonthlySalary: function () {
                var monthlyexpensesAsFreelancer = this.expensesAsFreelancer();
                var monthlySalary = this.step1.currentSalary;

                if (null != monthlySalary && null != monthlyexpensesAsFreelancer) {
                    return monthlySalary + monthlyexpensesAsFreelancer;
                }

            }
        };
    });

