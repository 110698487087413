angular
    .module("app")
    .factory("CategoryFactory", function () {
        return {
            getAll: function () {
                var amounts_text =
                    "IT & Programación	USD	ARS	Reais	pesos chilenos	pesos colombianos	euros	pesos mexicanos	soles peruanos	pesos uruguayos\n" +
                    "iron - $9	9	133	29	5859	26288	8	163	30	260\n" +
                    "bronze $10.5	10.5	155	34	6835	30670	10	191	35	303\n" +
                    "silver $17	17	250	55	11066	49655	16	308	57	490\n" +
                    "gold $25	25	367	81	16273	73022	23	453	83	720\n" +
                    "platinum $32	32	470	103	20829	93468	29	580	106	922\n" +
                    "hero $38	38	558	122	24735	110994	34	688	126	1095\n" +
                    "									\n" +
                    "Diseño y Multimedia									\n" +
                    "iron - $7	7	103	23	4557	20447	7	127	24	202\n" +
                    "bronze $8	8	118	26	5208	23367	8	145	27	231\n" +
                    "silver $12	12	177	39	7811	35051	11	218	40	346\n" +
                    "gold $17	17	250	55	11066	49655	16	308	57	490\n" +
                    "platinum $30	30	441	97	19527	87627	27	543	100	864\n" +
                    "hero $35	35	514	113	22782	102231	32	634	116	1008\n" +
                    "									\n" +
                    "Traducción y Contenidos									\n" +
                    "iron - $6	6	89	20	3906	17526	6	109	20	173\n" +
                    "bronze $7	7	103	23	4557	20447	7	127	24	202\n" +
                    "silver $9	9	133	29	5859	26288	8	163	30	260\n" +
                    "gold $11	11	162	36	7160	32130	10	200	37	317\n" +
                    "platinum $13	13	191	42	8462	37972	12	236	44	375\n" +
                    "hero $18	18	265	58	11717	52576	16	326	60	519\n" +
                    " 									\n" +
                    "Marketing y Ventas									\n" +
                    "iron - $8	8	118	26	5208	23367	8	145	27	231\n" +
                    "bronze $10	10	147	33	6509	29209	9	181	34	288\n" +
                    "silver $11	11	162	36	7160	32130	10	200	37	317\n" +
                    "gold $17	17	250	55	11066	49655	16	308	57	490\n" +
                    "platinum $22	22	323	71	14320	64260	20	399	73	634\n" +
                    "hero $30	30	441	97	19527	87627	27	543	100	864\n" +
                    "									\n" +
                    "Soporte Administrativo									\n" +
                    "iron - $7	7	103	23	4557	20447	7	127	24	202\n" +
                    "bronze $7	7	103	23	4557	20447	7	127	24	202\n" +
                    "silver $9	9	133	29	5859	26288	8	163	30	260\n" +
                    "gold $12	12	177	39	7811	35051	11	218	40	346\n" +
                    "platinum $14	14	206	45	9113	40893	13	254	47	404\n" +
                    "hero $17	17	250	55	11066	49655	16	308	57	490\n" +
                    "									\n" +
                    "Ingeniería y Manufactura									\n" +
                    "iron - $10	10	147	33	6509	29209	9	181	34	288\n" +
                    "bronze $12	12	177	39	7811	35051	11	218	40	346\n" +
                    "silver $19	19	279	61	12368	55497	17	344	63	548\n" +
                    "gold $22	22	323	71	14320	64260	20	399	73	634\n" +
                    "platinum $28	28	411	90	18226	81785	25	507	93	807\n" +
                    "hero $36	36	529	116	23433	105152	32	652	120	1037\n" +
                    "									\n" +
                    "Finanzas y Administración									\n" +
                    "iron - $9	9	133	29	5859	26288	8	163	30	260\n" +
                    "bronze $10	10	147	33	6509	29209	9	181	34	288\n" +
                    "silver $14	14	206	45	9113	40893	13	254	47	404\n" +
                    "gold $17	17	250	55	11066	49655	16	308	57	490\n" +
                    "platinum $25	25	367	81	16273	73022	23	453	83	720\n" +
                    "hero $30	30	441	97	19527	87627	27	543	100	864\n" +
                    "									\n" +
                    "Legales									\n" +
                    "iron - $15	15	221	49	9764	43814	14	272	50	432\n" +
                    "bronze $17	17	250	55	11066	49655	16	308	57	490\n" +
                    "silver $19	19	279	61	12368	55497	17	344	63	548\n" +
                    "gold $25	25	367	81	16273	73022	23	453	83	720\n" +
                    "platinum $29	29	426	93	18876	84706	26	525	96	836\n" +
                    "hero $33	33	485	106	21480	96389	30	598	110	951";

                var amount_cat_order = ["IT",
                    "D_M",
                    "T_C",
                    "M_S",
                    "A_S",
                    "E_M",
                    "F_A",
                    "L"
                ];
                var amount_currency_order = ["USD", "ARS", "BRL", "CLP", "COP", "EUR", "MXN", "PEN", "UYU"];
                var amount_plan_order = ["iron", "bronze", "silver", "gold", "platinum", "hero"];
                var amount_rows = amounts_text.split("\n");
                var amounts_all = {};

                var cat_index = 0;
                var currency_index = 0;
                var plan_index = 0;

                for (var j in amount_cat_order) {
                    amounts_all[amount_cat_order[j]] = { iron: {}, bronze: {}, silver: {}, gold: {}, platinum: {}, hero: {} };
                }
                for (var i in amount_rows) {
                    if (i == 0) continue;
                    var row = amount_rows[i].split("\t");
                    if (row[1].trim() == "") continue;

                    for (var currency_index in amount_currency_order) {
                        currency_index = parseInt(currency_index);
                        amounts_all[amount_cat_order[cat_index]][amount_plan_order[plan_index]][amount_currency_order[currency_index]] = parseFloat(row[currency_index + 1])
                    }
                    plan_index++;
                    if (plan_index == 6) {
                        plan_index = 0;
                        cat_index++;
                    }
                }
                return [
                    {
                        code: "IT",
                        name: "CAT_IT_AND_PROGRAMMING",
                        amounts: amounts_all["IT"]
                    },
                    {
                        code: "D_M",
                        name: "CAT_DESIGN_AND_MULTIMEDIA",
                        amounts: amounts_all["D_M"]

                    },
                    {
                        code: "T_C",
                        name: "CAT_TRANSLATE_CONTENT",
                        amounts: amounts_all["T_C"]
                    },
                    {
                        code: "M_S",
                        name: "CAT_MARKETING_AND_SALES",
                        amounts: amounts_all["M_S"]
                    },
                    {
                        code: "A_S",
                        name: "CAT_ADMINISTRATION_SUPPORT",
                        amounts: amounts_all["A_S"]
                    },
                    {
                        code: "E_M",
                        name: "CAT_ENGINEERING_AND_MANUFACTURING",
                        amounts: amounts_all["E_M"]
                    },
                    {
                        code: "F_A",
                        name: "CAT_FINANCE_AND_ADMINISTRATION",
                        amounts: amounts_all["F_A"]
                    },
                    {
                        code: "L",
                        name: "CAT_LEGAL",
                        amounts: amounts_all["L"]
                    }
                ];
            }
        };
    });


/**
 * Traducción y Contenidos
Marketing y Ventas
Soporte Administrativo
Ingeniería y Manufactura
Finanzas y Administración
Legales
 */