angular
  .module("app")
  .component("appStep1", {
    templateUrl: "app/templates/step1.html",
    controller: ['CurrencyFactory', 'formValueFactory', '$scope', "$timeout", "appConfig", function (CurrencyFactory, formValueFactory, $scope, $timeout, appConfig) {

      this.activeTab = 0;
      this.currencies = CurrencyFactory.getAll();
      this.nextStep = nextStep;
      this.prevStep = prevStep;
      this.values = formValueFactory.step1;

      for (var i in this.currencies) {
        if (appConfig.default_currency == this.currencies[i].code) {
          this.values["currency"] = this.currencies[i];
          break;
        }
      }
      function nextStep() {
        switch (this.activeTab) {
          case 0:
            // validar el primer formulario

            this.form1_1.$setSubmitted();
            if (!this.form1_1.$valid) {
              return;
            }
            break;
          case 1:
            // validar el segundo formulario

            this.form1_2.$setSubmitted();
            if (!this.form1_2.$valid) {
              return;
            }
            break;
          case 2:
            // validar el tercer formulario
            this.form1_3.$setSubmitted();
            if (!this.form1_3.$valid) {
              return;
            }
            break;
          default:
            return false;
        }
        if (this.activeTab < 2) {
          this.activeTab++;
          if (1 == this.activeTab) {
            var $element = jQuery("#input_office");
          } else if (2 == this.activeTab) {
            $element = jQuery("#healthInsurance_id");
          }
          $timeout(function () {
            jQuery('body').animate({ scrollTop: jQuery("#tab-1").offset().top - 61 }, '200');
            $element.focus();
          }, 300);

        } else {
          $scope.$emit("nextStep");
        }
      }

      function prevStep() {
        if (this.activeTab > 0) {
          this.activeTab--;
        }
      }
    }],
    controllerAs: "appStep1"
  });
