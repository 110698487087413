angular
  .module("app")
  .component("appStep3", {
    templateUrl: "app/templates/step3.html",
    controller: ["$scope", "CategoryFactory", "$uibModal", "formValueFactory", '$window', "$http", "$translate", function ($scope, CategoryFactory, $uibModal, formValueFactory, $window, $http, $translate) {
      var appStep3 = this;
      this.categories = CategoryFactory.getAll();
      this.values = formValueFactory;
      this.percentage_by_category = 100;

      for (var i in this.categories) {
        this.values["category"] = this.categories[i];
        break;
      }

      this.closeModal = function () {
        modalInstance.close();
      };

      this.totalperHour = function () {
        var monthlySalary = this.getAdjustedMonthlySalary();
        var monthlyBillableHours = this.getMonthlyBillableHoursTotal();
        if (null != monthlySalary && null != monthlyBillableHours) {
          return parseFloat(monthlySalary / monthlyBillableHours).toFixed(2);
        }
      };
      this.getMonthlyBillableHoursTotal = function () {
        var value = formValueFactory.getBillableYearHoursTotal();
        if (value != null) {
          return Math.floor(value / 12);
        }
      },
        this.getAdjustedMonthlySalary = function () {
          return formValueFactory.getAdjustedMonthlySalary()
        };
      this.getPercentageOfValue = function (value) {

      };


      this.buildPercentages = function () {
        var category = this.values.category;
        var totalAmount = this.totalperHour();
        var category_max_amount = 0;
        if (null != category && null != totalAmount) {
          for (var i in category.amounts) {
            if (category.amounts[i][formValueFactory.step1.currency.code] > category_max_amount) {
              category_max_amount = category.amounts[i][formValueFactory.step1.currency.code];
            }
          }
          if (totalAmount > category_max_amount) {
            category_max_amount = totalAmount;
          }
          category.percentages = {};
          for (var i in category.amounts) {
            category.percentages[i] = pencentage(category.amounts[i][formValueFactory.step1.currency.code], category_max_amount);
          }
          this.percentage_by_category = pencentage(totalAmount, category_max_amount)
        }
      };

      this.categoryAmount = function (plan) {
        return this.values.category.amounts[plan][this.formValueFactory.step1.currency.code];
      };
      this.mailto_url = "";

      $translate("MAILTO_SUBJECT").then(function (subject) {
        console.log(subject);
        $translate("MAILTO_BODY").then(function (mail_body) {
          console.log(mail_body);

          appStep3.mailto_url = "mailto:?subject=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent(mail_body);
        }, function () { });
      }, function () { });


      this.mailto = function () {
        var body = "Hola,\n" +
          "Entra a www.calculadorafreela.com  y podrás saber (gratis) cuánto cobrar tu hora como freelancer. ";
        return "mailto:?subject=" + encodeURIComponent("Con esta web podrás saber cuánto cobrar como freelancer!") + "&body=" + encodeURIComponent(body);
      };

      this.sharePopup = function (type) {
        var url = null;
        if ("twitter" == type) {
          $translate("TWITTER_POST").then(function (text) {
            text = encodeURIComponent(text);
            url = "https://twitter.com/intent/tweet?text=" + text;
            $window.open(url, "", "width=670,height=462,top=100,left=100");
          }, function (translationId) {
            text = encodeURIComponent(translationId);
            url = "https://twitter.com/intent/tweet?text=" + text;
            $window.open(url, "", "width=670,height=462,top=100,left=100");
          });


        } else if ('facebook' == type) {
          fbShare("https://www.calculadorafreela.com/", "", "", "", 670, 0);
        }

      };

      function fbShare(url, title, descr, image, winWidth, winHeight) {
        var winTop = (screen.height / 2) - (winHeight / 2);
        var winLeft = (screen.width / 2) - (winWidth / 2);
        window.open('http://www.facebook.com/sharer.php?s=100&p[title]=' + title + '&p[summary]=' + descr + '&p[url]=' + url + '&p[images][0]=' + image, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
      }

      function pencentage(amount, _100) {
        return amount * 100 / _100
      }

      $scope.$on("selectStep3", function () {
        //$http.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';

        var values = {
          currency: formValueFactory.step1.currency.code,
          current_salary: formValueFactory.step1.currentSalary,
          office: formValueFactory.step1.office,
          internet: formValueFactory.step1.internet,
          software: formValueFactory.step1.software,
          phone: formValueFactory.step1.phone,
          new_laptop: formValueFactory.step1.newLaptop,
          marketing: formValueFactory.step1.marketing,
          insurance: formValueFactory.step1.insurance,
          accountant: formValueFactory.step1.accountant,
          health_insurance: formValueFactory.step1.healthInsurance,
          taxes: formValueFactory.step1.taxes,
          others: formValueFactory.step1.others,

          yearly_billable_hours: formValueFactory.step2.yearlyBillableHours,
          sick_days: formValueFactory.step2.sickDays,
          business_days_of_vacation: formValueFactory.step2.businessDaysOfVacation,
          nonbillable_hours_percentage: formValueFactory.step2.nonbillableHoursPercentage,
          holidays: formValueFactory.step2.holidays,
          monthly_billable_hours: appStep3.getMonthlyBillableHoursTotal(),
          hour_value: appStep3.totalperHour()

        };

        $http({
          method: "post",
          data: values,
          url: "/calculate-hour",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
          },
          transformRequest: function (data, headers) {
            console.log(data);;
            return jQuery.param(data);
          }
        }).success(function (http, data) {
          console.log(http);
          console.log(data);
        });

        $window.setTimeout(function () {
          appStep3.showFinalModal();
        }, 15000);

      });

      this.showFinalModal = function () {
        var modalInstance = $uibModal.open({
          animation: $scope.animationsEnabled,
          templateUrl: 'app/templates/step3Modal.html',
          //windowTemplateUrl: "app/templates/step3ModalWindow.html",
          openedClass: "modal-open modal-workana",
          controller: ["$scope", "$uibModalInstance", function ($scope, $uibModalInstance) {
            $scope.cancel = function () {
              $uibModalInstance.dismiss('cancel');
            };
          }],
          size: "lg",
          controllerAs: "modalCtl"
        });
      };


    }],
    controllerAs: "appStep3"
  });
