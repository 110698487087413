angular
  .module("app")
  .factory("CurrencyFactory", function () {
    return {
      getAll: function () {
        return [
          { code: "ARS", name: "CURRENCY_ARS", symbol: "AR$" },
          { code: "BRL", name: "CURRENCY_BRL", symbol: "BRL" },
          { code: "CLP", name: "CURRENCY_CLP", symbol: "CLP" },
          { code: "COP", name: "CURRENCY_COP", symbol: "COP" },
          { code: "EUR", name: "CURRENCY_EUR", symbol: "EUR" },
          { code: "MXN", name: "CURRENCY_MXN", symbol: "MXN" },
          { code: "PEN", name: "CURRENCY_PEN", symbol: "PEN" },
          { code: "USD", name: "CURRENCY_USD", symbol: "USD" },
          { code: "UYU", name: "CURRENCY_UYU", symbol: "UYU" }
        ];
      }
    };
  });
