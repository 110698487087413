angular
  .module('app', ['ui.router', 'ngAnimate', 'ui.bootstrap', 'pascalprecht.translate', 'ngSanitize'])
  .config(['$translateProvider', function ($translateProvider) {
    $translateProvider.useStaticFilesLoader({
      prefix: 'app/i18n/',
      suffix: '.json'
    });

    var default_lang = "es";

    $translateProvider.preferredLanguage('es');
  }]);
