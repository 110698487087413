angular.module("app")
  .directive('formErrors', function () {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        errField: '=',
        errForm: '='
      },
      templateUrl: 'app/templates/form-errors.html'

    };
  });